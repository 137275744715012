import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from '@firebase/auth';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/FrontpageView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegisterView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: () => import('@/views/ForgotPasswordView.vue'),
  },
  {
    path: '/user-page',
    name: 'user_page',
    meta: { requiresAuth: true },
    component: () => import('@/views/UserPageView.vue'),
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import('@/views/BlogsView.vue'),
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: () => import('@/views/BlogView.vue'),  },
  {
    path: '/terms-of-service',
    name: 'terms_of_service',
    component: () => import('@/views/TermsOfServiceView.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: () => import('@/views/PrivacyPolicyView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404_not_found',
    component: () => import('@/views/NotFound404View.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return savedPosition || { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next('/login');
    } else {
      next();
    }
  });
});

export default router
