<template>
  <footer>
    <div class="footer-item">
      <router-link to="/terms-of-service">Terms of Service</router-link>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
    <div class="footer-item link">
      <a href="https://valoks.com/" target="_blank" class="link">
        <img src="@/assets/logo.png" class="logo" alt="Logo" loading="lazy">
      </a>
    </div>
    <p class="footer-item">&#169; 2024 Valoks I/S. All rights reserved.</p>
  </footer>
</template>

<style scoped>
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: rgb(var(--v-theme-background-lighten-2));
  div {
    gap: 1rem;
    a:hover {
      text-decoration: underline;
    }
  }
  p {
    justify-content: end;
  }
}

.link {
  justify-content: center;
}

.footer-item {
  width: 100%;
  display: flex;
}

.logo {
  height: 50px;
}

@media screen and (max-width: 950px) {
  footer {
    flex-direction: column;
    gap: 1rem;
    p, div {
      justify-content: center;
    }
  }
}
</style>