import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Icons
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { fa } from 'vuetify/iconsets/fa'
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'

const darkTheme = {
  dark: true,
  colors: {
    'text': '#ffffff',
    background: '#101010',
    'background-lighten-1': '#151515',
    'background-lighten-2': '#202020',
    'background-lighten-3': '#303030',
    primary: '#198754',
    secondary: '#ffffff',
    accent: '#ffffff',
    success: '#198754',
    warning: '#d4a017',
    error: '#dc3545',
    info: '#505050',
    'info-lighten-1': '#757575',
  },
}

const lightTheme = {
  dark: false,
  colors: {
    'text': '#000000',
    background: '#ffffff',
    'background-lighten-1': '#f5f5f5',
    'background-lighten-2': '#e0e0e0',
    'background-lighten-3': '#e0e0e0',
    primary: '#198754',
    secondary: '#000000',
    accent: '#000000',
    success: '#198754',
    warning: '#ffc107',
    error: '#dc3545',
    info: '#17a2b8',
    'info-lighten-1': '#757575',
  },
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'darkTheme',
    themes: {
      darkTheme,
      lightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
})

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(vuetify);
app.use(head);

app.mount('#app');
