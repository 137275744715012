import { getFirestore, collection, doc, getDoc, addDoc, deleteDoc, updateDoc, getDocs, where, query, orderBy } from '@firebase/firestore';
import { initializeApp } from '@firebase/app';
import {
  getAuth,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  updatePassword,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from '@firebase/auth';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// -----------------------
// AUTHENTICATION
// -----------------------
export const signup = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error("This email is already in use. Please use a different email.");
    } else {
      throw new Error("Signup failed, please try again.");
    }
  }
};

export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.log("Error logging in.", error);
    throw new Error("Login failed, please try again.");
  }
};

export const googleLogin = async () => {
  try {
    const userCredential = await signInWithPopup(auth, provider);
    return userCredential.user;
  } catch (error) {
    console.log("Error signing up through Google.", error);
    throw new Error("Login failed, please try again.");
  }
}

export const logout = async () => {
  try {
    signOut(auth);
  } catch (error) {
    console.log("Error logging out.", error);
    throw new Error("Signout failed, please try again.");
  }
};

// NOT IMPLEMENTED
export const updatePasswordWithNew = async (newPassword) => {
  try {
    const user = auth.user;
    const password = newPassword;
    updatePassword(user, password);
  } catch (error) {
    console.log("Error updating password with a new one.", error);
    throw new Error("Password update failed, please try again.");
  }
}

// NOT IMPLEMENTED
export const resetPasswordWithMail = async (email) => {
  try {
    sendPasswordResetEmail(email);
  } catch (error) {
    console.log("Error sending password reset email.", error);
    throw new Error("Password reset failed, please try again.");
  }
}

// NOT IMPLEMENTED
export const resetEmail = async () => {
  try {
    1
  } catch (error) {
    console.log("Error resetting email:", error);
    throw new Error("Email reset failed, please try again.");
  }
}

export const deleteAccountWithReauthentication = async (email, password) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(email, password);
    await reauthenticateWithCredential(user, credential);
    await deleteUser(user);
  } catch (error) {
    if (error.code === 'auth/invalid-credential') {
      throw new Error("The password is incorrect.");
    } else {
      throw new Error("Account deletion failed, please try again.");
    }
  }
};

export const onAuthChange = (callback) => {
  onAuthStateChanged(auth, (user) => {
    callback(user);
  });
};

export const isAdmin = async (email) => {
  const db = getFirestore();
  try {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userRole = userDoc.data().role;
      return userRole === "admin";
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking admin status:", error);
    throw new Error("Failed to check admin status.");
  }
};

export const getCurrentUser = () => {
  const auth = getAuth();
  return auth.currentUser;
};

// -----------------------
// NEWSLETTER
// -----------------------
export const saveNewsletterSignup = async (email) => {
  try {
    return await addDoc(collection(db, 'newsletterSignups'), { email });
  } catch (error) {
    console.error("Error signing up to the newsletter:", error);
    throw new Error("Failed to sign up for the newsletter, please try again.");
  }
};

// -----------------------
// BLOG
// -----------------------
export const getBlogs = async () => {
  try {
    const blogQuery = query(collection(db, "blogs"), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(blogQuery);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching blogs: ", error);
    throw new Error("Failed to load blogs.");
  }
};

export async function getBlogById(id) {
  const blogRef = doc(db, 'blogs', id);
  const blogSnap = await getDoc(blogRef);

  if (blogSnap.exists()) {
    return blogSnap.data();
  } else {
    console.log('No such document!');
    return null;
  }
}

export async function getBlogBySlug(slug) {
  const blogsRef = collection(db, 'blogs');
  const q = query(blogsRef, where('slug', '==', slug));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].data();
  } else {
    console.log('No such document!');
    return null;
  }
}

export const createBlog = async (title, markdown) => {
  try {
    const slug = await generateUniqueSlug(title);
    await addDoc(collection(db, "blogs"), {
      title,
      slug,
      markdown,
      createdAt: new Date(),
    });
  } catch (error) {
    console.error("Error creating blog: ", error);
    throw new Error("Failed to create blog.");
  }
};

export const deleteBlog = async (id) => {
  try {
    const blogRef = doc(db, 'blogs', id);
    await deleteDoc(blogRef);
  } catch (error) {
    console.error("Error deleting blog:", error);
    throw new Error('Failed to delete blog.');
  }
};

export const editBlog = async (id, slug, title, markdown) => {
  try {
    const blogRef = doc(db, 'blogs', id);
    await updateDoc(blogRef, {
      title: title,
      markdown: markdown,
      slug: generateSlug(title),
      updatedAt: new Date()
    });
  } catch (error) {
    console.error("Error updating blog:", error);
    throw new Error('Failed to update blog.');
  }
};

export function generateSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

export async function generateUniqueSlug(title) {
  let slug = generateSlug(title);
  const blogsRef = collection(db, 'blogs');
  
  let number = 1;
  let slugExists = true;

  while (slugExists) {
    const q = query(blogsRef, where('slug', '==', slug));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      slug = `${generateSlug(title)}-${number++}`;
    } else {
      slugExists = false;
    }
  }
  
  return slug;
}
