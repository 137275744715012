<template>
  <nav>
    <div class="left">
      <router-link to="/" class="logo">
        <img src="@/assets/logo.png" alt="Logo" loading="lazy">
      </router-link>
      <div class="burger">
        <a href="https://docs.vueboilerplate.com/" target="_blank">Documentation</a>
        <router-link to="/">Changelog</router-link>
        <router-link to="/blogs">Blog</router-link>
      </div>
    </div>
    <div>
      <router-link to="/user-page">
        <v-btn icon="mdi-account" variant="text"></v-btn>
      </router-link>
    </div>
  </nav>
</template>

<style scoped>
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(var(--v-theme-background-lighten-2));
  padding: 1rem;
  div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .left {
    gap: 3rem;
    .burger {
      gap: 3rem;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 50px;
}

@media screen and (max-width: 950px) {
  .burger {
    display: none;
  }
}
</style>